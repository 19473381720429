import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  html,
  body {
    font-size: 62.5%;
    width: 100%;
    min-height: 100vh;
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  `;
